import React from "react";
import classNames from "classnames";

type ButtonProps = {
  variant?: "outlined" | "contained";
  color?: "primary" | "secondary";
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
};

export const Button: React.FC<ButtonProps> = ({
  variant = "outlined",
  color = "primary",
  onClick,
  disabled = false,
  className,
  children,
}) => {
  const buttonClasses = classNames(
    "px-4 rounded-lg text-md border-2",
    {
      "h-10": !className?.includes("h-"),
      "bg-none border-primary text-primary":
        variant === "outlined" && color === "primary",
      "bg-primary border-primary text-contrast":
        variant === "contained" && color === "primary",
      "bg-none border-secondary text-secondary":
        variant === "outlined" && color === "secondary",
      "bg-secondary border-secondary text-white":
        variant === "contained" && color === "secondary",
    },
    className
  );

  return (
    <button
      className={buttonClasses}
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      {children}
    </button>
  );
};
