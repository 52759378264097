import "@/styles/globals.css";
import type { AppProps } from "next/app";
import { SessionProvider, signIn } from "next-auth/react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ProtectRoutes } from "@/components/ProtectRoutes";
import { Button } from "@/components/Button";
import { useRouter } from "next/router";

const queryClient = new QueryClient();

const TopNav = () => (
  <nav className="flex items-center justify-end p-2 px-4 gap-4 absolute w-full">
    <Button
      variant="outlined"
      onClick={() => signIn(undefined, { callbackUrl: "/projects" })}
    >
      log in
    </Button>
    <Button
      onClick={() => signIn(undefined, { callbackUrl: "/projects" })}
      variant="contained"
    >
      sign up
    </Button>
  </nav>
);

const Layout = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const hasTopNav = router.pathname === "/";
  return (
    <>
      <nav>{hasTopNav && <TopNav />}</nav>
      <main className="m-4 max-w-screen-2xl">
        <ProtectRoutes>{children}</ProtectRoutes>
      </main>
    </>
  );
};

export default function App({ Component, pageProps }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <SessionProvider session={pageProps.session}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </SessionProvider>
    </QueryClientProvider>
  );
}
