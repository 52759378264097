import Link from "next/link";
import { Button } from "@/components/Button";
import { useRouter } from "next/router";
import { signIn, useSession } from "next-auth/react";

export const ProtectRoutes = ({ children }: { children: React.ReactNode }) => {
  const { data: session, status } = useSession();
  const router = useRouter();

  if (router.pathname === "/") {
    return <>{children}</>;
  }

  if (status === "loading") {
    return <p>Loading...</p>;
  }

  if (!session) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <h1 className="text-4xl font-bold mb-4">
          You need to be logged in to view this page
        </h1>
        <Button onClick={signIn}>Log in</Button>
        <Link href="/">Back to home</Link>
      </div>
    );
  }

  return <>{children}</>;
};
